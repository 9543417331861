<template>
  <AppRow permission="WITHDRAW_ADMINISTRATION">
    <template v-slot>
      <v-col>
        <ApolloQuery
          :query="require('../graphql/queries/getWithdraws.gql')"
          :variables="{
            skip: (pageIndex - 1) * itemsPerPage,
            limit: itemsPerPage,
            status,
            walletType,
          }"
        >
          <template
            v-slot="{ result: { error: error, data: data }, isLoading: isLoading }"
          >
            <v-col>
              <!-- Error -->
              <AppNotification
                v-if="error"
                :message="error.message"
              />
              <!-- Result -->
              <v-card
                v-else-if="data || isLoading === 1"
                :loading="isLoading === 1"
                class="px-4 pb-3"
                flat
              >
                <template v-if="data">
                  <v-card-title>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        Ручных выводов: {{ data.getWithdraws.count }}
                      </v-col>
                      <v-col class="text-right">
                        <ApolloMutation
                          :mutation="require('../graphql/mutations/createManualWithdraw.gql')"
                          :refetch-queries="refetchQueriesAfterMyMutation"
                          :variables="{
                            userId: editedWithdraw.userId ? +editedWithdraw.userId : null,
                            amount: editedWithdraw.amount ? +editedWithdraw.amount : null,
                            purseType: editedWithdraw.purseType,
                            accountNumber: editedWithdraw.accountNumber ? String(editedWithdraw.accountNumber) : null,
                            accountName: editedWithdraw.accountName ? String(editedWithdraw.accountName) : null,
                            walletRecipient: editedWithdraw.walletRecipient ? String(editedWithdraw.walletRecipient) : null,
                            walletSender: editedWithdraw.walletSender ? String(editedWithdraw.walletSender) : null,
                          }"
                        >
                          <template v-slot="{ mutate, loading, error: createOrEditError }">
                            <v-dialog
                              v-model="withdrawDialog"
                              max-width="500px"
                              persistent
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  class="text-none"
                                  color="primary"
                                  depressed
                                  height="36"
                                  large
                                  v-on="on"
                                >
                                  Создать
                                </v-btn>
                              </template>

                              <v-card :loading="loading">
                                <v-card-title>
                                  <span class="text-h5">Создать ручной вывод</span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                      >
                                        <v-text-field
                                          v-model.number="editedWithdraw.amount"
                                          hide-details="auto"
                                          label="Сумма"
                                        />
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                      >
                                        <v-select
                                          v-model="editedWithdraw.purseType"
                                          :items="selectPurseType"
                                          background-color="#464646"
                                          class="rounded-lg"
                                          flat
                                          hide-details="auto"
                                          item-text="text"
                                          item-value="key"
                                          label="Валюта"
                                          solo
                                        />
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                      >
                                        <v-text-field
                                          v-model.number="editedWithdraw.userId"
                                          hide-details="auto"
                                          label="ID игрока"
                                        />
                                      </v-col>
                                      <v-col
                                        sm="6"
                                        cols="12"
                                      >
                                        <v-text-field
                                          v-model.number="editedWithdraw.accountNumber"
                                          hide-details="auto"
                                          label="Account Number"
                                        />
                                      </v-col>
                                      <v-col cols="12">
                                        <v-text-field
                                          v-model.number="editedWithdraw.accountName"
                                          hide-details="auto"
                                          label="Account Name"
                                        />
                                      </v-col>
                                      <v-col
                                        sm="6"
                                        cols="12"
                                      >
                                        <v-text-field
                                          v-model="editedWithdraw.walletRecipient"
                                          hide-details="auto"
                                          label="Wallet Recipient"
                                        />
                                      </v-col>
                                      <v-col
                                        sm="6"
                                        cols="12"
                                      >
                                        <v-text-field
                                          v-model="editedWithdraw.walletSender"
                                          hide-details="auto"
                                          label="Wallet Sender"
                                        />
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer />

                                  <v-btn
                                    color="primary"
                                    text
                                    @click="createWithdrawClose"
                                  >
                                    Отмена
                                  </v-btn>
                                  <v-btn
                                    :disabled="
                                      !editedWithdraw.amount
                                        || !editedWithdraw.purseType
                                        || !editedWithdraw.userId
                                        || !editedWithdraw.accountNumber
                                    "
                                    color="primary"
                                    text
                                    @click="mutate()"
                                  >
                                    Создать
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <AppNotification
                              v-if="createOrEditError"
                              :icon-disabled="true"
                              :message="createOrEditError.message"
                            />
                          </template>
                        </ApolloMutation>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-data-table
                    :headers="headers"
                    :items="data.getWithdraws.withdraws"
                    :items-per-page="itemsPerPage"
                    disable-sort
                    hide-default-footer
                  >
                    <template v-slot:top>
                      <ApolloMutation
                        :mutation="require('../graphql/mutations/acceptManualWithdraw.gql')"
                        :refetch-queries="refetchQueriesAfterMyMutation"
                        :variables="{
                          withdrawId,
                          status: isAccept ? accepted : denied,
                          reason: reason === '' ? null : reason,
                        }"
                      >
                        <template v-slot="{ mutate, loading, error: acceptOrDenyError }">
                          <v-dialog
                            v-model="dialog"
                            max-width="400px"
                            persistent
                          >
                            <v-card :loading="loading">
                              <v-card-title>{{ dialogTitle }}</v-card-title>
                              <v-card-text v-if="!isAccept">
                                <v-text-field
                                  v-model="reason"
                                  label="Причина"
                                />
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer />

                                <v-btn
                                  color="primary"
                                  text
                                  @click="dialog = false"
                                >
                                  Отмена
                                </v-btn>
                                <v-btn
                                  color="error"
                                  text
                                  @click="mutate()"
                                >
                                  {{ buttonText }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <AppNotification
                            v-if="acceptOrDenyError"
                            :icon-disabled="true"
                            :message="acceptOrDenyError.message"
                          />
                        </template>
                      </ApolloMutation>
                    </template>

                    <template v-slot:[`item.createdAt`]="{ item }">
                      {{ item.createdAt | moment('DD.MM.YYYY, HH:mm') }}
                    </template>

                    <template v-slot:[`item.amount`]="{ item }">
                      {{ item.amount }}
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn
                        :to="{ name: 'users', params: { id: item.userId }}"
                        class="text-none"
                        depressed
                        height="36"
                        x-small
                        style="margin-top: .4rem"
                      >
                        Подробнее об игроке
                      </v-btn>
                      <v-btn
                        class="text-none ma-1"
                        depressed
                        height="36"
                        x-small
                        style="margin-top: .7rem !important"
                        @click.stop="denyWithdraw(item)"
                      >
                        Отклонить
                      </v-btn>
                      <v-btn
                        class="text-none ma-1"
                        color="primary"
                        depressed
                        height="36"
                        x-small
                        style="margin-top: .7rem !important"
                        @click.stop="acceptWithdraw(item)"
                      >
                        Одобрить
                      </v-btn>
                    </template>
                  </v-data-table>
                  <v-divider />

                  <div class="text-center pt-3">
                    <v-pagination
                      v-model="pageIndex"
                      :length="Math.ceil(data.getWithdraws.count / itemsPerPage)"
                      :total-visible="9"
                    />

                    <v-select
                      v-model="itemsPerPage"
                      :items="[5, 10, 15]"
                      class="pa-4 pt-5"
                      label="Строк на странице"
                      @change="firstPage"
                    />
                  </div>
                </template>
              </v-card>

              <!-- No result -->
              <AppNotification
                v-else
                message="Нет результата :("
              />
            </v-col>
          </template>
        </ApolloQuery>
      </v-col>
    </template>
  </AppRow>
</template>
<script>
import AppRow from '@/components/AppRow.vue';
import AppNotification from '@/components/AppNotification.vue';
import getWithdraws from '@/graphql/queries/getWithdraws.gql';

export default {
  components: {
    AppNotification,
    AppRow,
  },
  data() {
    return {
      headers: [
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'userId:', value: 'userId', class: 'white--text text-subtitle-2' },
        { text: 'Full name:', value: 'accountName', class: 'white--text text-subtitle-2' },
        { text: 'Сумма:', value: 'amount', class: 'white--text text-subtitle-2' },
        { text: 'Валюта:', value: 'purseType', class: 'white--text text-subtitle-2' },
        { text: 'Номер счёта:', value: 'accountNumber', class: 'white--text text-subtitle-2' },
        { text: 'Доп:', value: 'walletRecipient', class: 'white--text text-subtitle-2' },
        { text: 'Статус:', value: 'status', class: 'white--text text-subtitle-2' },
        { text: 'Дата:', value: 'createdAt', class: 'white--text text-subtitle-2' },
        { text: '', value: 'action', align: 'center' },
      ],
      editedWithdraw: {
        purseType: 'INR',
      },
      selectPurseType: [
        { text: 'INR', key: 'INR' },
        { text: 'BTC', key: 'BTC' },
        { text: 'USD', key: 'USD' },
        { text: 'EUR', key: 'EUR' },
        { text: 'ETC', key: 'ETC' },
        { text: 'UAH', key: 'UAH' },
        { text: 'RUB', key: 'RUB' },
        { text: 'LTC', key: 'LTC' },
        { text: 'DEMO', key: 'DEMO' },
        { text: 'ETH', key: 'ETH' },
        { text: 'BRL', key: 'BRL' },
        { text: 'BCH', key: 'BCH' },
        { text: 'BNB', key: 'BNB' },
        { text: 'DASH', key: 'DASH' },
        { text: 'DOGE', key: 'DOGE' },
        { text: 'TRX', key: 'TRX' },
        { text: 'USDT', key: 'USDT' },
        { text: 'XMR', key: 'XMR' },
        { text: 'ZEC', key: 'ZEC' },
        { text: 'XRP', key: 'XRP' },
        { text: 'KZT', key: 'KZT' },
        { text: 'VND', key: 'VND' },
        { text: 'UZS', key: 'UZS' },
        { text: 'IDR', key: 'IDR' },
        { text: 'AZN', key: 'AZN' },
        { text: 'KGS', key: 'KGS' },
        { text: 'PKR', key: 'PKR' },
        { text: 'BDT', key: 'BDT' },
        { text: 'CLP', key: 'CLP' },
        { text: 'PEN', key: 'PEN' },
        { text: 'COP', key: 'COP' },
        { text: 'MXN', key: 'MXN' },
        { text: 'PYG', key: 'PYG' },
        { text: 'UYU', key: 'UYU' },
        { text: 'BOB', key: 'BOB' },
        { text: 'CRC', key: 'CRC' },
        { text: 'GTQ', key: 'GTQ' },
        { text: 'PAB', key: 'PAB' },
        { text: 'NIO', key: 'NIO' },
        { text: 'ARS', key: 'ARS' },
        { text: 'SVC', key: 'SVC' },
      ],
      itemsPerPage: 5,
      pageIndex: 1,
      walletType: 'manual_withdraw',
      status: 'PENDING',
      dialog: false,
      withdrawDialog: false,
      reason: '',
      withdrawId: null,
      accepted: 'ACCEPTED',
      denied: 'DENIED',
      isAccept: false,
    };
  },
  computed: {
    dialogTitle() {
      return this.isAccept ? 'Перевести в статус "отправлено"' : 'Отклонить заявку на вывод';
    },
    buttonText() {
      return this.isAccept ? 'Перевести' : 'Отклонить';
    },
  },
  methods: {
    refetchQueriesAfterMyMutation() {
      this.dialog = false;
      this.withdrawDialog = false;

      return [{
        query: getWithdraws,
        variables: {
          skip: (this.pageIndex - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          status: this.status,
          walletType: this.walletType,
        },
      }];
    },
    denyWithdraw(item) {
      this.isAccept = false;
      this.withdraw(item);
    },
    acceptWithdraw(item) {
      this.isAccept = true;
      this.withdraw(item);
    },
    withdraw(item) {
      this.dialog = true;
      this.withdrawId = +item.id;
      this.reason = '';
    },
    firstPage() {
      this.pageIndex = 1;
    },
    createWithdrawClose() {
      this.withdrawDialog = false;
      this.$nextTick(() => {
        this.editedWithdraw = {
          purseType: 'INR',
        };
      });
    },
  },
};
</script>
